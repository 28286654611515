import * as React from "react"

// styles
const pageStyles = {
  padding: "96px",
  background: '#000',
  color: "#fff",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <h1>Loading</h1>
    </main>
  )
}

export default NotFoundPage
